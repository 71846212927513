<template>
  <!-- 单点登录查看已完成试卷 -->
  <div class="external_view_FinishPaper">
    <CommonFinishPaper v-if="show"
                       ref="commonFinishPaper"
                       :autoid="mautoid"
                       :username="username"></CommonFinishPaper>
  </div>
</template>

<script>
import { getResumeSsoInfo } from "@api/wx.js";
import CommonFinishPaper from './commonFinishPaper'
// exitPrompt
export default {
  components: {
    CommonFinishPaper
  },
  data () {
    let guid = this.$route.query.guid || '910ca68b964844158e1902f6952934aa'
    return {
      guid,
      mautoid: 0,
      username: '',
      show: false,
    }
  },
  created () {
    this.getResumeSsoInfoData()
  },
  methods: {
    // 获取问卷的单点登录数据
    getResumeSsoInfoData () {
      getResumeSsoInfo({
        guid: this.guid
      }).then(res => {
        if (res.data.length > 0)        {
          this.mautoid = res.data[0].autoid
          this.username = res.data[0].username
          this.show = true
        }
      })
    },
  }

};
</script>
 
<style lang="less" scoped>
.external_view_FinishPaper {
  width: 100%;
  height: 100%;
}
</style>